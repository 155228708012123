import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["aria-label", "disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.tag), {
    class: _normalizeClass(_ctx.className)
  }, {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default"),
      _createElementVNode("button", {
        class: "btn btn-close",
        "data-bs-dismiss": "modal",
        "aria-label": _ctx.$t('text.abort'),
        disabled: _ctx.disabled,
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.emitClosed && _ctx.emitClosed(...args)))
      }, null, 8, _hoisted_1)
    ]),
    _: 3
  }, 8, ["class"]))
}