import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["id"]
const _hoisted_2 = { class: "modal-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "modal fade",
    tabindex: "-1",
    id: _ctx.id
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(`modal-dialog modal-dialog-centered ${_ctx.sizeClass}`)
    }, [
      _createElementVNode("div", _hoisted_2, [
        _renderSlot(_ctx.$slots, "default")
      ])
    ], 2)
  ], 8, _hoisted_1))
}