import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, vModelSelect as _vModelSelect, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withDirectives as _withDirectives, vShow as _vShow } from "vue"

const _hoisted_1 = ["value", "id", "multiple", "size", "aria-label"]
const _hoisted_2 = ["value"]
const _hoisted_3 = ["value"]
const _hoisted_4 = ["for"]
const _hoisted_5 = {
  key: 1,
  class: "text-muted fs-5 mt-1"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["mb-3", _ctx.label ? 'form-floating': ''])
  }, [
    _withDirectives(_createElementVNode("select", {
      value: _ctx.inputValue,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.inputValue) = $event)),
      onInput: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleChange && _ctx.handleChange(...args))),
      class: _normalizeClass(["form-select", _ctx.errorMessage ? ' is-invalid' : '']),
      id: _ctx.name,
      multiple: _ctx.isMultiple,
      size: _ctx.isMultiple ? 5 : 1,
      "aria-label": _ctx.label
    }, [
      (!_ctx.isMultiple && _ctx.emptyValue !== null)
        ? (_openBlock(), _createElementBlock("option", {
            value: _ctx.emptyValue,
            key: "empty"
          }, _toDisplayString(_ctx.$t('form_validation.empty_select')), 9, _hoisted_2))
        : _createCommentVNode("", true),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (options) => {
        return (_openBlock(), _createElementBlock("option", {
          key: options.id,
          value: options.id
        }, _toDisplayString(options.label), 9, _hoisted_3))
      }), 128))
    ], 42, _hoisted_1), [
      [_vModelSelect, _ctx.inputValue]
    ]),
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("label", {
          key: 0,
          for: _ctx.name,
          class: _normalizeClass(_ctx.labelStyle)
        }, _toDisplayString(_ctx.label), 11, _hoisted_4))
      : _createCommentVNode("", true),
    _withDirectives(_createElementVNode("div", { class: "invalid-feedback" }, _toDisplayString(_ctx.errorMessage), 513), [
      [_vShow, !!_ctx.errorMessage]
    ]),
    (_ctx.isMultiple)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(_ctx.$t('base.form_select_multiple_help_text')), 1))
      : _createCommentVNode("", true)
  ], 2))
}